<template>
  <b-overlay
    :show="applyOverlayD"
    :class="applyOverlayD ? 'p-2 mb-1' : 'mb-1'"
  >
    <template #overlay>
      <div class="d-flex align-items-center justify-content-center mt-1">
        <b-spinner
          type="border"
          variant="primary"
        />
      </div>
      <div class="d-flex justify-content-center">
        <p class="pt-1">
          {{ $t('loading') }}
        </p>
      </div>
    </template>
    <b-row v-if="!applyOverlayD">
      <!-- Marca -->
      <b-col md="12">
        <b-row>
          <b-col md="6">
            <h5 class="mt-1">
              <strong>{{ $t('clubs.intellectualProperty.formTrademarks.labels.trademarkData') }}</strong>
            </h5>
          </b-col>
          <b-col md="6">
            <span
              v-if="nTrademarks > 1 && checkPermission(['DELETE_TRADEMARK_REGISTRATION', 'ALLOW_ALL'])"
              class="float-right mt-1 ml-1"
            >
              {{ $t( 'buttons.delete') }}
              <b-button
                class="btn-icon rounded-circle"
                variant="danger"
                @click="$emit('delete-trademark', actions.trademarkId)"
              >
                <feather-icon icon="Trash2Icon" />
              </b-button>
            </span>
            <span
              v-if="checkPermission(['EDIT_TRADEMARK_REGISTRATION', 'ALLOW_ALL'])"
              class="float-right mt-1"
            >
              {{ $t( 'buttons.edit') }}
              <b-button
                class="btn-icon rounded-circle"
                variant="secondary"
                @click="$emit('edit-trademark', actions.trademarkId)"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
            </span>
          </b-col>
        </b-row>
        <hr style="margin-top: 8px;">
      </b-col>
      <b-col
        v-if="trademarkInfoD"
        md="12"
      >
        <b-list-group>
          <b-list-group-item>
            <b-row>
              <b-col md="6">
                <strong class="float-left">{{ $t('clubs.intellectualProperty.formTrademarks.labels.requestType') }}</strong>
              </b-col>
              <b-col md="6">
                <span class="float-left">{{ trademarkInfoD.request_type.labelables[0].label }}</span>
              </b-col>
            </b-row>
          </b-list-group-item>
          <b-list-group-item>
            <b-row>
              <b-col md="6">
                <strong class="float-left">{{ $t('clubs.intellectualProperty.formTrademarks.labels.trademarkType') }}</strong>
              </b-col>
              <b-col md="6">
                <span class="float-left">{{ trademarkInfoD.trademark_type.labelables[0].label }}</span>
              </b-col>
            </b-row>
          </b-list-group-item>
          <b-list-group-item>
            <b-row>
              <b-col md="6">
                <strong class="float-left">{{ $t('clubs.intellectualProperty.formTrademarks.labels.classNumber') }}</strong>
              </b-col>
              <b-col md="6">
                <span class="float-left">{{ trademarkInfoD.class_number }}</span>
              </b-col>
            </b-row>
          </b-list-group-item>
          <b-list-group-item>
            <b-row>
              <b-col md="6">
                <strong class="float-left">{{ $t('clubs.intellectualProperty.formTrademarks.labels.classDescription') }}</strong>
              </b-col>
              <b-col md="6">
                <span class="float-left">{{ trademarkInfoD.class_description }}</span>
              </b-col>
            </b-row>
          </b-list-group-item>
          <b-list-group-item>
            <b-row>
              <b-col md="6">
                <strong class="float-left">{{ $t('clubs.intellectualProperty.formTrademarks.labels.denomination') }}</strong>
              </b-col>
              <b-col md="6">
                <span class="float-left">{{ trademarkInfoD.denomination }}</span>
              </b-col>
            </b-row>
          </b-list-group-item>
          <b-list-group-item>
            <b-row>
              <b-col md="6">
                <strong class="float-left">{{ $t('clubs.intellectualProperty.formTrademarks.labels.registerNumber') }}</strong>
              </b-col>
              <b-col md="6">
                <span class="float-left">{{ trademarkInfoD.register_number }}</span>
              </b-col>
            </b-row>
          </b-list-group-item>
          <b-list-group-item>
            <b-row>
              <b-col md="6">
                <strong class="float-left">{{ $t('clubs.intellectualProperty.formTrademarks.labels.dateOfConcession') }}</strong>
              </b-col>
              <b-col md="6">
                <span class="float-left">{{ trademarkInfoD.date_of_concession == null ? $t('generic.undefinedDate') : trademarkInfoD.date_of_concession | formatDate() }}</span>
              </b-col>
            </b-row>
          </b-list-group-item>
          <b-list-group-item>
            <b-row>
              <b-col md="6">
                <strong class="float-left">{{ $t('clubs.intellectualProperty.formTrademarks.labels.effectiveDate') }}</strong>
              </b-col>
              <b-col md="6">
                <span class="float-left">{{ trademarkInfoD.effective_date == null ? $t('generic.undefinedDate') : trademarkInfoD.effective_date | formatDate() }}</span>
              </b-col>
            </b-row>
          </b-list-group-item>
          <b-list-group-item>
            <b-row>
              <b-col md="6">
                <strong class="float-left">{{ $t('clubs.intellectualProperty.formTrademarks.labels.applicationForTheRegistration') }}</strong>
              </b-col>
              <b-col md="6">
                <span>. . .</span>
                <b-media
                  class="float-right"
                  no-body
                >
                  <span
                    class="i-c-pointer cursor-pointer float-right"
                    @click="showFile = !showFile"
                  >
                    {{ $t('generic.see') }}
                    <feather-icon
                      v-if="!showFile"
                      icon="FileIcon"
                      size="17"
                      style="padding-bottom: 2px"
                    />
                  </span>
                  <div
                    v-if="showFile == true"
                    class="ml-2"
                    style="display: flex;"
                  >
                    <div
                      v-if="checkPermission(['VIEW_CONTRACT_FILE_MEMBERS', 'ALLOW_ALL']) && showFile == true"
                      class="float-right"
                      style="display: flex;"
                    >
                      <div v-if="isObject(trademarkInfoD.application_media_file) && trademarkInfoD.application_media_file.mime_type != 'application/pdf'">
                        <image-viewer
                          class="cursor-pointer mr-1"
                          :document-id="trademarkInfoD.application_file_hash"
                          :url-thumb="trademarkInfoD.application_media_file.url_thumb"
                          :height="'30'"
                          :width="'30'"
                          :permission-show="'VIEW_CONTRACT_FILE_MEMBERS'"
                          :permission-download="'DOWNLOAD_CONTRACT_FILE_MEMBERS'"
                        />
                      </div>
                      <div v-else>
                        <ViewerDocumentModal
                          class="cursor-pointer mr-1"
                          :title="trademarkInfoD.application_media_file ? trademarkInfoD.application_media_file.title : ''"
                          :document-id="trademarkInfoD.application_file_hash"
                          :height="'30'"
                          :width="'30'"
                          :permission-show="'VIEW_CONTRACT_FILE_MEMBERS'"
                          :permission-download="'DOWNLOAD_CONTRACT_FILE_MEMBERS'"
                        />
                      </div>
                      <div v-if="trademarkInfoD.application_file_hash != null && checkPermission(['DOWNLOAD_CONTRACT_FILE_MEMBERS', 'ALLOW_ALL'])">
                        <span
                          class="float-right cursor-pointer"
                          @click="handleDownloadFile(trademarkInfoD.application_file_hash, trademarkInfoD.application_media_file.title)"
                        >
                          <i
                            class="icon-0-icons-dark-download btn-cursor"
                            style="font-size: 20px;"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </b-media>
              </b-col>
            </b-row>
          </b-list-group-item>
          <b-list-group-item>
            <b-row>
              <b-col md="6">
                <strong class="float-left">{{ $t('clubs.intellectualProperty.formTrademarks.labels.trademarkImage') }}</strong>
              </b-col>
              <b-col md="6">
                <span>. . .</span>
                <b-media
                  class="float-right"
                  no-body
                >
                  <span
                    class="i-c-pointer cursor-pointer float-right"
                    @click="showFileTwo = !showFileTwo"
                  >
                    {{ $t('generic.see') }}
                    <feather-icon
                      v-if="!showFileTwo"
                      icon="FileIcon"
                      size="17"
                      style="padding-bottom: 2px"
                    />
                  </span>
                  <div
                    v-if="showFileTwo == true"
                    class="ml-2"
                    style="display: flex;"
                  >
                    <div
                      v-if="checkPermission(['VIEW_CONTRACT_FILE_MEMBERS', 'ALLOW_ALL']) && showFileTwo == true"
                      class="float-right"
                      style="display: flex;"
                    >
                      <div v-if="isObject(trademarkInfoD.image_media_file) && trademarkInfoD.image_media_file.mime_type != 'application/pdf'">
                        <image-viewer
                          class="cursor-pointer mr-1"
                          :document-id="trademarkInfoD.image_file_hash"
                          :url-thumb="trademarkInfoD.image_media_file.url_thumb"
                          :height="'30'"
                          :width="'30'"
                          :permission-show="'VIEW_CONTRACT_FILE_MEMBERS'"
                          :permission-download="'DOWNLOAD_CONTRACT_FILE_MEMBERS'"
                        />
                      </div>
                      <div v-else>
                        <ViewerDocumentModal
                          class="cursor-pointer mr-1"
                          :title="trademarkInfoD.image_media_file ? trademarkInfoD.image_media_file.title : ''"
                          :document-id="trademarkInfoD.image_file_hash"
                          :height="'30'"
                          :width="'30'"
                          :permission-show="'VIEW_CONTRACT_FILE_MEMBERS'"
                          :permission-download="'DOWNLOAD_CONTRACT_FILE_MEMBERS'"
                        />
                      </div>
                      <div v-if="trademarkInfoD.image_file_hash != null && checkPermission(['DOWNLOAD_CONTRACT_FILE_MEMBERS', 'ALLOW_ALL'])">
                        <span
                          class="float-right cursor-pointer"
                          @click="handleDownloadFile(trademarkInfoD.image_file_hash, trademarkInfoD.image_media_file.title)"
                        >
                          <i
                            class="icon-0-icons-dark-download btn-cursor"
                            style="font-size: 20px;"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </b-media>
              </b-col>
            </b-row>
          </b-list-group-item>
        </b-list-group>
      </b-col>
      <!--/ Marca -->
      <login-modal
        :permission="validPermission"
        @change="toAccess"
      />
    </b-row>
    <b-row v-else>
      <b-col
        md="12"
        class="i-height-div"
      />
    </b-row>
  </b-overlay>
</template>

<script>
/* eslint-disable object-curly-newline */
import { mapActions } from 'vuex'
import { isObject, downloadFile } from '@core/utils/utils'
import { BRow, BCol, BListGroup, BListGroupItem, BButton } from 'bootstrap-vue'
import trademarkService from '@/services/trademarkService'
import ImageViewer from '@/views/member/ImageViewer.vue'
import ViewerDocumentModal from '@/views/member/ViewerDocumentModal.vue'
import LoginModal from '@/views/member/LoginModal.vue'

export default {
  components: {
    BRow,
    BCol,
    BListGroup,
    BListGroupItem,
    BButton,
    ImageViewer,
    ViewerDocumentModal,
    LoginModal,
  },

  props: {
    actions: {
      type: Object,
      default: () => {},
    },
    nTrademarks: {
      type: Number,
      default: null,
    },
    checkPermission: {
      type: Function,
      default: null,
    },
  },

  data() {
    return {
      validPermission: null,
      showFile: false,
      showFileTwo: false,
      trademarkInfoD: {},
      applyOverlayD: false,
    }
  },

  created() {
    this.fetchTrademarkInfo()
  },

  methods: {
    downloadFile,
    isObject,
    ...mapActions({ mediaFileShow: 'mediaFile/show' }),

    fetchTrademarkInfo() {
      this.applyOverlayD = true

      trademarkService.getTrademarkInfo(this.actions.trademarkId).then(({ data }) => {
        this.trademarkInfoD = data.data
        this.applyOverlayD = false
      }).catch(error => {
        this.applyOverlayD = false
        this.responseCatch(error)
      })
    },

    async toAccess(permissionData) {

    },

    async handleDownloadFile(fileHash, title) {
      this.isLoading = true
      await this.mediaFileShow(fileHash).then(response => {
        const { data: dataDocument } = response
        this.downloadFile(dataDocument, title)
        this.isLoading = false
      }).catch(error => {
        this.isLoading = false
        this.responseCatch(error)
      }).finally(() => {
        this.isLoading = false
      })
    },
  },
}
</script>

<style scoped>
.i-c-pointer:hover {
  color: #6abeff;
}
</style>
